import React, { useCallback, useRef } from 'react';
import { List } from 'antd';
import SelectableOption from '@copilot/common/components/cardOption';
import { NurtureListOptionType } from '@copilot/common/store/models/const/enum';
import { NurtureListOptionText, NurtureListNumConnections, numConnectionsLabel } from './const';
import EditableTagList, { EditableTagListHandle } from '@copilot/common/components/editableTagList';
import { appInsights } from '@copilot/common/components/snippets/applicationInsights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

interface ISelectableListItemProps {
	title: string;
	optionType: NurtureListOptionType;
	selectedOption?: NurtureListOptionType;
	handleOptionSelected: (optionType: NurtureListOptionType) => void;
}

const SelectableListItem: React.FC<ISelectableListItemProps> = (props) => {
	const {
		title, optionType, selectedOption, handleOptionSelected, children,
	} = props;

	const onItemClick = useCallback(() => {
		handleOptionSelected(optionType);
	}, [handleOptionSelected]);

	return (
		<List.Item>
			<SelectableOption
				title={title}
				bodyText={NurtureListOptionText[optionType].body}
				onClick={onItemClick}
				checked={selectedOption === optionType}
				faded={selectedOption !== undefined && selectedOption !== optionType}
				buttonSize="small"
			>
				{children}
			</SelectableOption>
		</List.Item>
	);
};

interface INurtureListProps {
	selectedOption?: NurtureListOptionType;
	numConnections: NurtureListNumConnections;
	selectedTags: string[];
	availableTags: string[];
	onSaveTagList: (tags: string[]) => void;
	onOptionSelected: (option: NurtureListOptionType) => void;
}

/**
 * Wizard body of nurture list step
 * @param {NurtureListOptionType} selectedOption list option that is selected
 * @param {NurtureListNumConnections} numConnections number of connections for the old and tag options
 * @param {string[]} selectedTags tags selected for the list built from tags
 * @param {string[]} availableTags tag options to appear in the dropdown
 * @param {function} onSaveTagList called when the user clicks save after editing the tag list
 * @param {function} onOptionSelected called when an option is selected
 */
const NurtureList: React.FC<INurtureListProps> = (props) => {
	const {
		selectedOption,
		numConnections,
		selectedTags,
		availableTags,
		onSaveTagList,
		onOptionSelected,
	} = props;
	const tagListRef = useRef<EditableTagListHandle>(null);

	const getSelectableOptionTitle = useCallback((optionType: NurtureListOptionType) => (
		`${NurtureListOptionText[optionType].title} ${numConnectionsLabel(optionType, numConnections)}`
	), [numConnections]);

	const handleOptionSelected = useCallback((optionType: NurtureListOptionType) => {
		onOptionSelected(optionType);
		if (optionType !== NurtureListOptionType.Tag) {
			tagListRef?.current?.resetTagList();
		}
	}, [onOptionSelected]);

	return (
		<List itemLayout="vertical">
			<SelectableListItem
				title={getSelectableOptionTitle(NurtureListOptionType.Old)}
				optionType={NurtureListOptionType.Old}
				selectedOption={selectedOption}
				handleOptionSelected={handleOptionSelected}
			/>
			<SelectableListItem
				title={getSelectableOptionTitle(NurtureListOptionType.NeverReplied)}
				optionType={NurtureListOptionType.NeverReplied}
				selectedOption={selectedOption}
				handleOptionSelected={handleOptionSelected}
			/>
			<SelectableListItem
				title={getSelectableOptionTitle(NurtureListOptionType.Tag)}
				optionType={NurtureListOptionType.Tag}
				selectedOption={selectedOption}
				handleOptionSelected={handleOptionSelected}
			>
				<EditableTagList
					ref={tagListRef}
					selectedTags={selectedTags}
					availableTags={availableTags}
					editLabel="+ Add More Tags"
					placeholder="Please choose at least one tag..."
					onSaveTagList={onSaveTagList}
				/>
			</SelectableListItem>
			<SelectableListItem
				title={getSelectableOptionTitle(NurtureListOptionType.Empty)}
				optionType={NurtureListOptionType.Empty}
				selectedOption={selectedOption}
				handleOptionSelected={handleOptionSelected}
			/>
		</List>
	);
};

export default withAITracking(appInsights.reactPlugin, NurtureList, 'Nurture Onboard Nurture List Step');
