import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';

const CardCol = styled(Col)`
	background-color: #f2f2f2;
	border-radius: 8px;
	padding: 32px 28px;
	margin: 4px;
	min-width: 270px !important;
	max-width: 320px !important;
`;

export interface IIconCardProps {
	icon: React.ReactNode;
	text: string;
	description?: string;
}

export const IconCard: React.FC<IIconCardProps> = ({ text, icon, description }) => (
	<CardCol key={text}>
		<Row align="middle" justify="space-around" style={{ marginBottom: '8px', fontSize: '32px' }}>
			{icon}
		</Row>
		<Row align="middle" justify="space-around" style={{ fontSize: '20px' }}>
			{text}
		</Row>
		{description && (
			<Row align="middle" justify="space-around" style={{ marginTop: '8px', fontSize: '16px' }}>
				{description}
			</Row>
		)}
	</CardCol>
);

interface IIconCardListProps {
	iconCards: IIconCardProps[];
}

/**
 * List of cards each with an icon and a brief description
 * @param iconCards data for the cards
 */
const IconCardList: React.FC<IIconCardListProps> = (props) => {
	const { iconCards } = props;
	return (
		<Row align="stretch" justify="space-between" style={{ marginBottom: '36px', minWidth: '670px', alignSelf: 'center' }}>
			{iconCards.map((card) => (
				<IconCard
					icon={card.icon}
					text={card.text}
					description={card.description}
					key={card.text}
				/>
			))}
		</Row>
	);
};

export default IconCardList;
