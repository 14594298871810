import { Row, Col, Button } from 'antd';
import React from 'react';
import styled from 'styled-components';

const StyledButton = styled(Button)`
	padding: 4px !important;
	height: auto;
`;

export interface IInfoSectionProps {
	title?: string;
	description?: string;
	url?: string;
}

/**
 * Additional Information Section on the Nurture Onboarding Wizard
 * @param title section title
 * @param description section description
 * @param url link to direct to when Learn More is clicked
 */
const InfoSection: React.FC<IInfoSectionProps> = (props) => {
	const { title, description, url } = props;

	return (
		<>
			<Row>
				<Col>
					<h3>{title}</h3>
				</Col>
			</Row>
			<Row style={{ marginTop: '8px', marginBottom: '24px' }}>
				<Col>
					{description}
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<StyledButton block href={url} target="_blank">Learn More</StyledButton>
				</Col>
			</Row>
		</>
	);
};

export default InfoSection;
