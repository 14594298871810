import React from 'react';
import {
	Layout, Row, Col, Button,
} from 'antd';
import styled from 'styled-components';
import IconCardList, { IIconCardProps } from '@copilot/common/components/iconCardList';
import { Colors } from '@copilot/common/utils/constant';

const StyledLayout = styled(Layout)`
	margin-top: ${(props) => props.theme['@layout-header-height']};
	background-color: ${Colors.WizardBackground};
	text-align: center;
	padding-top: 6em;
`;

const ReturnLinkButton = styled(Button)`
	font-size: 14px;
	color: #c2c2c2;

	span {
		text-decoration: underline;
	}
`;

interface IWizardWelcomeHeaderProps {}

/**
 * Onboard Wizard welcome page header
 */
const WizardWelcomeHeader: React.FC<IWizardWelcomeHeaderProps> = ({ children }) => <>{children}</>;

interface IWizardWelcomeContentProps {
	title: string;
	description: React.ReactNode;
	learnMoreUrl?: string;
	iconCardData: IIconCardProps[];
	buttonLabel: string;
	linkLabel?: string;
	onStartClicked: () => void;
	onLinkClicked?: () => void;
}

/**
 * Main Content of Wizard Welcome page with a short description and icon cards
 * @param title title
 * @param description description
 * @param learnMoreUrl url to open when clicking the Learn More link
 * @param iconCardData data for icon cards
 * @param buttonLabel label for start button
 * @param linkLabel label for link under start button
 * @param onStartClicked called when start button is clicked
 * @param onLinkClicked called when the link under the start button is clicked
 */
const WizardWelcomeContent: React.FC<IWizardWelcomeContentProps> = (props) => {
	const {
		title, description, learnMoreUrl, iconCardData, buttonLabel, linkLabel, onStartClicked, onLinkClicked,
	} = props;
	return (
		<StyledLayout>
			<Row align="middle" justify="space-around" style={{ marginBottom: '28px' }}>
				<Col span={16}>
					<h1 style={{ marginBottom: '12px', fontSize: '36px' }}>{title}</h1>
					<Row align="middle" justify="space-around">
						<Col style={{ maxWidth: '600px', fontSize: '20px' }}>
							{description}
							{' '}
							{learnMoreUrl && <a href={learnMoreUrl} target="_blank">Learn More</a>}
						</Col>
					</Row>
				</Col>
			</Row>
			<IconCardList iconCards={iconCardData} />
			<Row align="middle" justify="space-around" style={{ marginBottom: '18px' }}>
				<Button type="primary" onClick={onStartClicked} style={{ width: '200px' }}>{buttonLabel}</Button>
			</Row>
			{linkLabel && onLinkClicked && (
				<Row align="middle" justify="space-around">
					<ReturnLinkButton type="link" onClick={onLinkClicked}>{linkLabel}</ReturnLinkButton>
				</Row>
			)}
		</StyledLayout>
	);
};

interface IWizardWelcomeStaticProps {
	Header: typeof WizardWelcomeHeader;
	Content: typeof WizardWelcomeContent;
}

interface IWizardWelcomeProps {}

/**
 * Onboard Wizard welcome page with a header and content
 */
const WizardWelcome: React.FC<IWizardWelcomeProps> & IWizardWelcomeStaticProps = (props) => {
	const { children } = props;
	return (
		<Layout style={{ minHeight: '100vh' }}>
			{children}
		</Layout>
	);
};

WizardWelcome.Header = WizardWelcomeHeader;
WizardWelcome.Content = WizardWelcomeContent;

export default WizardWelcome;
