import {
	Col, Row, Skeleton, Spin,
} from 'antd';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DarkHeaderWithSupportLinks } from '../../../components/menus/header/Logo';
import { CampaignSelectors } from '../../../store/selectors/campaign';
import { OrganizationMemberSelectors } from '../../../store/selectors/organizationMember';
import BaseLayout from '../../layouts/base';
import NewUserOnboardWizard from './wizard';

export const NewUserOnboardPage: FC = () => {
	const history = useHistory();
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const incompleteCampaign = useSelector(CampaignSelectors.getFirstIncompleteCampaign);

	if (activeMember?.isOnboarded) history.push('/');

	if (!activeMember || !incompleteCampaign) return (
		<Spin size="large">
			{' '}
			<Skeleton paragraph={{ rows: 15 }} />
			{' '}
		</Spin>
	);

	return (
		<BaseLayout>
			<BaseLayout.Header>
				<DarkHeaderWithSupportLinks activeMember={activeMember} />
			</BaseLayout.Header>
			<BaseLayout.Content>
				<Row justify="center" style={{ marginTop: '48px' }}>
					<Col xs={24} xxl={18}>
						<NewUserOnboardWizard campaignId={incompleteCampaign.id} activeMember={activeMember} />
					</Col>
				</Row>
			</BaseLayout.Content>
		</BaseLayout>
	);
};
