import React, { useMemo } from 'react';
import { UserOutlined } from '@ant-design/icons';
import {
	Avatar, Col, Dropdown, Row, Menu,
} from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { IOrganizationMember } from '@copilot/common/store/models/redux';
import modalManager from '@copilot/common/utils/modalManager';
import { handleUserLogout } from '@copilot/common/utils';
import { Config } from '@copilot/common/config';

const StyledAvatar = styled(Avatar)`
	&& {
		background-color: ${(props) => props.theme['@user-avatar-color']};
		color: #2c323d;
		margin: 0 10px;
	}
`;

interface UserDisplayProps {
	user: IOrganizationMember | null;
	fontColor?: string; // uses default colour if unspecified
}

export const UserDisplay: React.FC<UserDisplayProps> = (props) => {
	const { user, fontColor } = props;
	const { firstName = '', lastName = '' } = user ?? {};
	const initials = React.useMemo(() => `${firstName?.substr(0, 1) ?? ''}${lastName?.substr(0, 1) ?? ''}`, [firstName, lastName]);
	return (
		<Row justify="space-between" align="middle">
			<Col>
				<StyledAvatar icon={!initials ? <UserOutlined /> : undefined}>{initials}</StyledAvatar>
			</Col>
			<Col style={{ fontSize: '15px', color: fontColor, display: 'inline-block' }}>
				<strong>{`${firstName ?? ''} ${lastName ?? ''}`}</strong>
			</Col>
		</Row>
	);
};

interface UserMenuWithDropdownProps {
	user: IOrganizationMember | null;
	admin: IOrganizationMember | null;
	fontColor?: string; // uses default colour if unspecified
	showProfile: boolean;
	showCalendly: boolean;
	showSettings: boolean;
}

const withUserMenuDropdown = <T extends UserMenuWithDropdownProps>(Component: React.FC<UserDisplayProps>) => (props: T) => {
	const {
		user, admin, showProfile, showCalendly, showSettings,
	} = props || {};

	const openCalendlySettingsModal = React.useCallback(() => {
		console.log('this is value of admin:', admin);
		if (admin) {
			console.log('we are in the callback function for openCalendly');
			modalManager.openCalendlySettingsModal({ uid: admin.userId });
		}
	}, [admin]);

	const openSettingsModal = () => {
		modalManager.openPasswordSettingsModal({});
	};
	const userMenuOverlay = useMemo(() => {
		const menu = (
			<Menu>
				{showProfile && (
					<Menu.Item key="profile">
						<Link to="/settings">
							<span>Profile</span>
						</Link>
					</Menu.Item>
				)}
				{showCalendly && (
				<Menu.Item key="calendly" onClick={openCalendlySettingsModal}>
					<span>Calendly</span>
				</Menu.Item>
				)}
				{!Config.isB2CEnabled && showSettings && (
					<Menu.Item key="settings" onClick={openSettingsModal}>
						<span>Settings</span>
					</Menu.Item>
				)}
				<Menu.Item
					key="logout"
					onClick={() => handleUserLogout(Config.isB2CEnabled)}
				>
					<span>Logout</span>
				</Menu.Item>
			</Menu>
		);
		return menu;
	}, [user, openCalendlySettingsModal]);

	return (
		<Dropdown overlay={userMenuOverlay} trigger={['click']} placement="bottomRight">
			<Col style={{ cursor: 'pointer' }}>
				<Component {...props} />
			</Col>
		</Dropdown>
	);
};

export default withUserMenuDropdown(UserDisplay);
