import {
	Alert, Divider, List,
} from 'antd';
import React, {
	useState, useEffect, useCallback, useRef,
} from 'react';
import SelectableOption from '@copilot/common/components/cardOption';
import { NodeTimerPeriodEnum, NodeTimerPeriodStringEnum, NurtureMessageTypeEnum } from '@copilot/common/store/models/const/enum';
import { LONGER_TIME_UNITS, MAX_MESSAGE_NUMBER } from '@copilot/common/pages/settings/message/const';
import { NurtureMessagesExamples, NurtureMessageTemplateKeys } from './const';
import { convertToOrdinalAbbr } from '@copilot/common/utils';
import OnboardMessageEditor from '../../common/onboardMessageEditor';
import { OnboardMessage } from '@copilot/data/requests/models';
import { appInsights } from '@copilot/common/components/snippets/applicationInsights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import styled from 'styled-components';

const StyledList = styled(List)`
	& > div > div > div > div > .${(props) => props.theme['@ant-prefix']}-col {
		height: calc(100% - 16px);
		padding: 0 8px;
	}
`;

interface INurtureMessagingProps {
	messages: OnboardMessage[];
	selectedStrategy: NurtureMessageTypeEnum;
	selectedTemplates: string[];
	onUpdate: (messages: OnboardMessage[]) => void;
	onStrategyUpdate: (strategy: NurtureMessageTypeEnum) => void;
	onSelectedTemplateUpdate: (templateKey: string, template: string) => void;
	resetMessageTemplates: () => void;
}

const MessageTypes = [
	{
		key: NurtureMessageTypeEnum.CheckIn,
		title: 'Check In',
		bodyText: 'Re-engage in conversation with your prospect with a check-in message.',
	}, {
		key: NurtureMessageTypeEnum.Info,
		title: 'Sharing Information',
		bodyText: 'Share relevant information like an article or whitepaper with your list of prospects.',
	}, {
		key: NurtureMessageTypeEnum.Event,
		title: 'Event Invitation',
		bodyText: 'Let your list of prospects know about an upcoming event.',
	}, {
		key: NurtureMessageTypeEnum.Business,
		title: 'Business Development',
		bodyText: 'Meeting/Demo funnel',
	},
];

const DefaultMessages = () => {
	const messages = [{
		label: '1st',
		time: 1,
		period: NodeTimerPeriodEnum.Months,
	}];

	for (let i = 2; i <= MAX_MESSAGE_NUMBER; i++) {
		messages.push({
			label: convertToOrdinalAbbr(i),
			time: 1,
			period: NodeTimerPeriodEnum.Weeks,
		});
	}
	return messages;
};

/**
 * [Presentational] Onboard Step on Nurture Messagings
 * @param messages saved messages during onboarding
 * @param selectedStrategy messaging strategy that is selected
 * @param selectedTemplates array of templates that are selected for the messaging options
 * @param onUpdate called when messages is updated
 * @param onStrategyUpdate called when messaging strategy is updated
 * @param onSelectedTemplateUpdate updates template with the given template key
 * @param resetMessageTemplates resets all message templates that get passed in
 */
const NurtureMessaging: React.FC<INurtureMessagingProps> = (props) => {
	const {
		messages, selectedStrategy, selectedTemplates, onUpdate, onStrategyUpdate, onSelectedTemplateUpdate, resetMessageTemplates,
	} = props;
	const isInitialRender = useRef(true);
	const [templateOptions, setTemplateOptions] = useState<{ [strategy: string]: { [step: string]: string } }>();

	useEffect(() => {
		setTemplateOptions(NurtureMessagesExamples[NurtureMessageTypeEnum[selectedStrategy]]);
		if (isInitialRender.current) {
			isInitialRender.current = false;
		} else {
			resetMessageTemplates();
		}
	}, [selectedStrategy, resetMessageTemplates]);

	//hide timing editor on initiate message as we want to default it to 1 month // CPD-2493
	const handleHideTiming = (idx: number) => idx === 0;

	const handleSelectedTemplateUpdate = useCallback((messageIndex: number, template: string) => {
		if (NurtureMessageTemplateKeys[messageIndex]) {
			onSelectedTemplateUpdate(NurtureMessageTemplateKeys[messageIndex], template);
		}
	}, [onSelectedTemplateUpdate]);

	return (
		<>
			<Alert
				type="warning"
				message="For regulated industries, please ensure compliance approval is obtained in advance of activating your campaign"
				style={{ marginBottom: '10px' }}
			/>
			<h3>Example Strategies</h3>
			<p> There are many different ways you can use Nurture Campaigns. We've put together four examples of different campaigns you may wish to run and some messaging templates for each. </p>
			<StyledList
				grid={{ column: 2 }}
				dataSource={MessageTypes}
				renderItem={(item: any) => (
					<List.Item style={{ height: '100%' }}>
						<SelectableOption
							title={item.title}
							bodyText={item.bodyText}
							onClick={() => onStrategyUpdate(item.key as NurtureMessageTypeEnum)}
							checked={selectedStrategy === item.key}
							faded={selectedStrategy != undefined && selectedStrategy != item.key}
							buttonSize="small"
							customStyle={{ height: '100%' }}
						/>
					</List.Item>
				)}
			/>
			<Divider />
			<OnboardMessageEditor
				messages={messages}
				defaultSetting={DefaultMessages()}
				templates={templateOptions}
				selectedTemplates={selectedTemplates}
				handleHideTiming={handleHideTiming}
				timeUnits={[NodeTimerPeriodStringEnum.Hours, ...LONGER_TIME_UNITS]}
				onUpdate={onUpdate}
				updateSelectedTemplate={handleSelectedTemplateUpdate}
			/>
		</>
	);
};

export default withAITracking(appInsights.reactPlugin, NurtureMessaging, 'Nurture Onboard Messaging Step');
