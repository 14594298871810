import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import BaseSidebar, { IMenuGroupItem, MenuIconType } from '@copilot/common/components/menus/sidebar';
import { AppSelectors } from '@copilot/common/store/selectors/app';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { CampaignSelectors } from '@copilot/common/store/selectors/campaign';
import { ExclamationCircleFilled, PlusOutlined, StopFilled } from '@ant-design/icons';
import { Menu, Dropdown, Tag } from 'antd';
import { useCreateCampaignForIndividual } from '@copilot/common/hooks/campaign';
import { CampaignApprovalStatus, CampaignType } from '@copilot/data/responses/interface';
import modalManager from '@copilot/common/utils/modalManager';
import { OrganizationMemberManager } from '@copilot/data';
import { useDebounce } from '@copilot/common/hooks/common';
import { Config } from '@copilot/common/config';
import {
	getCampaignTypeIcon,
	displayCampaignOptions,
	getSimpleCampaignNameValidator, getSortedCampaigns, canCreateCampaignIndividual,
} from '@copilot/common/utils/campaign';
import { CampaignDefaultName } from '@copilot/common/utils/constant';
import { MenuKeys } from './constant';

import type { IMenuItem } from '@copilot/common/components/menus/sidebar';
import styled from 'styled-components';
import { useProspectCampaignOnboardTracking } from '@copilot/common/pages/onboard/wizard/tracking';
import { SidebarCampaignClicks, useSidebarTracking } from '@copilot/common/utils/sidebar/tracking';
import { AgencyCode } from '@copilot/common/config/constant';

const SidebarFESettingKeyMapping = {
	[MenuKeys.Campaign]: 'isCampaignAccordionExpanded',
};

const StyledTag = styled(Tag)`
	font-size: 11px;
	font-weight: bold;
	padding: 0px 4px; 
	margin-right: 0px;
	background-color: #ffc32e;
	border-color: #ffc32e;
`;

// added to prevent ellipsis on plus icon for "New Campaign"
const StyledDropdown = styled(Dropdown)`
	position: absolute;
`;

const StyledIconSpan = styled.span`
	position: absolute;
	left: 0;
	margin-left: 13px;
`;

const Sidebar: React.FC = () => {
	const location = useSelector(AppSelectors.getCurrentLocation);
	const pathname = useMemo(() => {
		const pathSplit = location.pathname.split('/');
		if (!pathSplit[1]) return '/';
		if (pathSplit[1] === 'settings') return '/settings';
		else return location.pathname;
	}, [location.pathname]);
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const isCSAdmin = !!useSelector(OrganizationMemberSelectors.getAdminMember);
	const appSettings = useSelector(AppSelectors.getSettings);
	const [openKeys, setOpenKeys] = React.useState<string[]>([]);
	const campaigns = useSelector(CampaignSelectors.getCampaigns);
	React.useEffect(() => {
		if (appSettings?.isCampaignAccordionExpanded ?? campaigns.length === 1)
			setOpenKeys((keys) => {
				const newKeys = [...keys];
				newKeys.push(MenuKeys.Campaign);
				return newKeys;
			});
	}, [appSettings?.isCampaignAccordionExpanded, campaigns.length]);
	const updateSidebarTracking = useSidebarTracking('Sidebar', null);
	const handleSidebarTrackingUpdate = (buttonName: SidebarCampaignClicks) => {
		updateSidebarTracking({ buttonClicked: buttonName });
	};

	const createCampaign = useCreateCampaignForIndividual();
	const trackEvent = useProspectCampaignOnboardTracking('Sidebar');
	const onCreateNewCampaign = useCallback(() =>
		(type: CampaignType) => {
			handleSidebarTrackingUpdate('New Campaign');
			modalManager.openCampaignCreationAlert({
				onContinue: () =>
					modalManager.openCampaignCreationModal({
						nameEditorValidator: getSimpleCampaignNameValidator(campaigns),
						onCreate: async (name: string) => {
							trackEvent({ buttonClicked: 'Create new campaign' });
							await createCampaign(name, type);
						},
					}),
			});
		},
	[createCampaign, campaigns]
	);

	const renderCreateCampaign = useMemo(() => {
		//TODO: Remove isCSAdmin after Nurture roll-out
		if (isCSAdmin) {
			return (
				<StyledDropdown overlay={displayCampaignOptions(onCreateNewCampaign())} trigger={['click']}>
					<div>
						{CampaignDefaultName.NewCampaign}
						<PlusOutlined style={{ color: 'white', margin: '0px' }} />
					</div>
				</StyledDropdown>
			);
		} else {
			const createProspectingCampaign = onCreateNewCampaign();

			return (
				<div onClick={() => createProspectingCampaign(CampaignType.Prospecting)} style={{ position: 'absolute' }}>
					{CampaignDefaultName.NewCampaign}
					<PlusOutlined style={{ color: 'white', margin: '0px' }} />
				</div>
			);
		}
	}, [onCreateNewCampaign, isCSAdmin]);

	const menuItems = React.useMemo<React.ComponentProps<typeof BaseSidebar>['menuItems']>(() => {
		const allCampaigns = campaigns ?? [];

		const campaignElements: IMenuItem[] = [{
			key: '/campaigns',
			to: '/campaigns',
			label: 'All Campaigns',
			onClick: () => { handleSidebarTrackingUpdate('All Campaigns'); },
		}];

		const campaignsToAdd = getSortedCampaigns(allCampaigns).slice(0, 5);

		const campaignsToShow: IMenuItem[] = campaignsToAdd.map((campaign) => ({
			key: `/campaign/${campaign.id}`,
			to: `/campaign/${campaign.id}`,
			label: campaign.name,
			icon: getCampaignTypeIcon(campaign.type),
			onClick: () => { handleSidebarTrackingUpdate('Campaign'); },
			notification:
				campaign.isNew ? (
					<span style={{ position: 'absolute', left: 0, marginLeft: '5px' }}>
						<StyledTag>New</StyledTag>
					</span>
				) :
					campaign.approvalStatus === CampaignApprovalStatus.Incomplete ? (
						<StyledIconSpan>
							<ExclamationCircleFilled />
						</StyledIconSpan>
					) : campaign.approvalStatus === CampaignApprovalStatus.Disconnected ? (
						<StyledIconSpan>
							<StopFilled />
						</StyledIconSpan>
					) : undefined,
		}));

		campaignElements.push(...campaignsToShow);

		if (activeMember && canCreateCampaignIndividual(activeMember)) {
			campaignElements.push({
				key: '/campaign/create',
				render: () => renderCreateCampaign,
			});
		}

		const items: (IMenuItem | IMenuGroupItem)[] = [
			{
				key: MenuKeys.Root,
				to: MenuKeys.Root,
				icon: MenuIconType.DashboardOutlined,
				label: 'Dashboard',
			},
			{
				key: MenuKeys.Inbox,
				to: MenuKeys.Inbox,
				icon: MenuIconType.MessageOutlined,
				label: 'Inbox',
			},
			{
				key: MenuKeys.Outbox,
				to: MenuKeys.Outbox,
				icon: MenuIconType.HourglassOutlined,
				label: 'Outbox',
			},
			{
				key: MenuKeys.Campaign,
				icon: MenuIconType.NotificationOutlined,
				label: 'My Campaigns',
				subMenuItems: campaignElements,
			},
			{
				key: MenuKeys.Sent,
				to: MenuKeys.Sent,
				icon: MenuIconType.UploadOutlined,
				label: 'Sent',
			},
			{
				key: MenuKeys.Connections,
				to: '/connections',
				icon: MenuIconType.TeamOutlined,
				label: 'Connections',
			},
			{
				key: MenuKeys.Settings,
				to: MenuKeys.Settings,
				icon: MenuIconType.SettingOutlined,
				label: 'Settings',
			},
		];

		if (Config.isAgency && Config.agencyCode === AgencyCode.cleverly) {
			items.push({
				key: MenuKeys.Referral,
				icon: MenuIconType.DollarOutlined,
				label: 'Referral Payout',
				onClick: () => {
					window.open(Config.referralURL);
				},
			});
		}
		if (!Config.isAgency) {
			items.push({
				key: MenuKeys.Referral,
				to: MenuKeys.Referral,
				icon: MenuIconType.RocketOutlined,
				label: 'Referral',
				badge: (
					<StyledTag>
						Free Month
					</StyledTag>),
			});
		}

		return items;
	}, [campaigns]);

	const updateAccordionState = React.useCallback(
		async (keys: string[]) => {
			if (!activeMember?.id) return;
			const currentKeys = keys.reduce((acc, k) => {
				acc[k] = true;
				return acc;
			}, {} as { [k: string]: boolean });

			const accordionsState = Object.keys(SidebarFESettingKeyMapping).reduce((acc, key) => {
				acc[SidebarFESettingKeyMapping[key]] = currentKeys[key] ?? false;
				return acc;
			}, {} as { [k: string]: boolean });

			await OrganizationMemberManager.updateFEUserSetting(activeMember.id, accordionsState);
		},
		[activeMember?.id]
	);

	const onMenuOpenChange = useDebounce(updateAccordionState, Config.debounceDelay);

	const menuOptions = React.useMemo(() => {
		const options: React.ComponentProps<typeof Menu> = {
			mode: 'inline',
			theme: Config.isAgency ? 'light' : 'dark',
			selectedKeys: [pathname],
			onOpenChange: (keys) => {
				const stringKeys = keys.map((k) => k.toString());
				onMenuOpenChange(stringKeys);
				setOpenKeys(stringKeys);
			},
			openKeys,
		};

		return options;
	}, [pathname, campaigns, openKeys, onMenuOpenChange]);

	if (!activeMember) return null;
	return <BaseSidebar menuOptions={menuOptions} menuItems={menuItems} />;
};

export default Sidebar;
