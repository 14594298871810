import React, { useMemo } from 'react';
import { Row, Alert, Button } from 'antd';
import styled from 'styled-components';

const StyledAlert = styled(Alert)`
	line-height: 1 !important;
	padding: 8px 12px !important;

	.${(props) => props.theme['@ant-prefix']}-alert-icon {
		position: relative;
		top: 0;
		left: 0;
		margin-right: 6px;
	}
`;

interface SaveStatusButtonProps {
	unsavedLabel?: string;
	savedLabel?: string;
	isStateSaved: boolean;
	onSaveClicked?: () => void;
}

/**
 * Displays the save status, and allows the user to click the button to save if unsaved
 *
 * @param {string} unsavedLabel label for the unsaved state button
 * @param {string} savedLabel label for the saved state label
 * @param {boolean} isStateSaved if the current state is saved or not
 * @param {function} onSaveClicked handle when button is clicked in the unsavedState
 */
const SaveStatusButton: React.FC<SaveStatusButtonProps> = (props) => {
	const {
		unsavedLabel = 'Unsaved',
		savedLabel = 'Saved',
		isStateSaved,
		onSaveClicked,
	} = props;

	const unsavedState = useMemo(() => (
		<Button onClick={() => (onSaveClicked ? onSaveClicked() : '')}>{unsavedLabel}</Button>
	), [onSaveClicked, unsavedLabel]);

	const savedState = useMemo(() => (
		<StyledAlert
			message={savedLabel}
			type="success"
			showIcon
		/>
	), [savedLabel]);

	return (
		<Row align="middle" style={{ marginRight: '8px' }}>
			{isStateSaved ? savedState : unsavedState}
		</Row>
	);
};

export default SaveStatusButton;
