import React, { useCallback } from 'react';
import {
	Row, Col, Divider, Button,
} from 'antd';
import {
	NurtureListOption, NURTURE_REVIEW_SUB_DESCRIPTION, NurtureReviewIncompleteText, NurtureListSummaryText,
} from './const';
import { NurtureListOptionType } from '@copilot/common/store/models/const/enum';
import { WizardStepNode } from '@copilot/common/pages/wizard/nurtureOnboard/const';
import { convertToOrdinalAbbr } from '@copilot/common/utils';
import styled from 'styled-components';
import { UtilityFunctions } from '@copilot/common/utils/common';
import { appInsights } from '@copilot/common/components/snippets/applicationInsights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

const StyledHeader = styled.h3`
	font-weight: bold;
`;

const StyledSubHeader = styled.h4`
    margin-bottom: 0;
    font-weight: bold;
`;

interface INurtureListSummaryProps {
	nurtureListOption?: NurtureListOption;
}

const NurtureListSummary: React.FC<INurtureListSummaryProps> = ({ nurtureListOption }) => {
	if (nurtureListOption === undefined) {
		return <Row>{NurtureReviewIncompleteText[WizardStepNode.NURTURE_LIST]}</Row>;
	}
	switch (nurtureListOption.optionType) {
		case NurtureListOptionType.Old:
			return (
				<StyledSubHeader>
					{NurtureListSummaryText[NurtureListOptionType.Old]}
					{' '}
					{nurtureListOption.connectionsLabel}
				</StyledSubHeader>
			);
		case NurtureListOptionType.NeverReplied:
			return (
				<StyledSubHeader>
					{NurtureListSummaryText[NurtureListOptionType.NeverReplied]}
					{' '}
					{nurtureListOption.connectionsLabel}
				</StyledSubHeader>
			);
		case NurtureListOptionType.Tag:
			return (
				<>
					<StyledSubHeader>
						{NurtureListSummaryText[NurtureListOptionType.Tag]}
					</StyledSubHeader>
					<Row>
						{nurtureListOption.tags.join(', ')}
						{' '}
						{nurtureListOption.connectionsLabel}
					</Row>
				</>
			);
		case NurtureListOptionType.Empty:
			return <StyledSubHeader>{NurtureListSummaryText[NurtureListOptionType.Empty]}</StyledSubHeader>;
		default:
			return UtilityFunctions.assertUnreachable(nurtureListOption);
	}
};

interface IMessagingSummaryProps {
	messages: string[];
}

const MessagingSummary: React.FC<IMessagingSummaryProps> = ({ messages }) => {
	if (messages.length > 0 && messages[0] !== '') {
		return (
			<>
				{messages.map((message, index) => {
					const messageNumber = convertToOrdinalAbbr(index + 1);
					return (
						<Row key={`${messageNumber}-message`} style={{ marginBottom: '24px' }}>
							<Col>
								<StyledSubHeader>{`${messageNumber} Message`}</StyledSubHeader>
								<Row>{message}</Row>
							</Col>
						</Row>
					);
				})}
			</>
		);
	} else return <Row>{NurtureReviewIncompleteText[WizardStepNode.NURTURE_MESSAGING]}</Row>;
};

interface INurtureReviewProps {
	nurtureListOption?: NurtureListOption;
	messages: string[];
	setCurrentStep?: (step: number) => void; // populated by Wizard.Step.MainContent
}

/**
 * Wizard body for nurture onboarding summary page
 * @param {NurtureListOption} nurtureListOption selected nurture list option
 * @param {string[]} messages automated message sequence
 * @param {function} setCurrentStep sets the current onboard step
 */
const NurtureReview: React.FC<INurtureReviewProps> = (props) => {
	const { nurtureListOption, messages, setCurrentStep } = props;

	const onEditClick = useCallback((step: number) => {
		if (setCurrentStep) {
			setCurrentStep(step);
		}
	}, [setCurrentStep]);

	return (
		<>
			<p style={{ marginTop: '-6px' }}>{ NURTURE_REVIEW_SUB_DESCRIPTION }</p>
			<Divider />
			<Row style={{ alignItems: 'baseline' }}>
				<StyledHeader>Nurture List</StyledHeader>
				<Button type="link" size="small" onClick={() => onEditClick(WizardStepNode.NURTURE_LIST)}>(Edit)</Button>
			</Row>
			<NurtureListSummary nurtureListOption={nurtureListOption} />
			<Row style={{ alignItems: 'baseline', marginTop: '36px' }}>
				<StyledHeader>Messaging</StyledHeader>
				<Button type="link" size="small" onClick={() => onEditClick(WizardStepNode.NURTURE_MESSAGING)}>(Edit)</Button>
			</Row>
			<MessagingSummary messages={messages} />
		</>
	);
};

export default withAITracking(appInsights.reactPlugin, NurtureReview, 'Nurture Onboard Review Step');
