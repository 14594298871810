// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BfbWc4JRwCRIhQwweLeK {\n  color: #52c41a;\n  font-size: 72px;\n  animation: Ux7OOfAXSx1XcuXe5qBl 0.7s cubic-bezier(0.68, -0.55, 0.27, 1.55), AbWhhuCVs71FFPyrgiTg 1s 0.5s;\n  border-radius: 50%;\n  background-image: linear-gradient(53deg, transparent 0%, transparent 55%, #52c41a 55%, #52c41a 100%);\n  animation-fill-mode: forwards;\n  background-position: -21px;\n}\n@keyframes Ux7OOfAXSx1XcuXe5qBl {\n  0% {\n    transform: scale(0.2);\n  }\n  100% {\n    transform: scale(1);\n  }\n}\n@keyframes AbWhhuCVs71FFPyrgiTg {\n  0% {\n    background-position: -21px;\n  }\n  100% {\n    background-position: 21px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./../../common/pages/settings/linkedInAuthResults.module.less"],"names":[],"mappings":"AAGA;EACE,cAAA;EACA,eAAA;EACA,wGAAA;EACA,kBAAA;EACA,oGAAA;EACA,6BAAA;EACA,0BAAA;AAFF;AAMA;EACE;IACE,qBAAA;EAJF;EAOA;IACE,mBAAA;EALF;AACF;AAQA;EACE;IACE,0BAAA;EANF;EAQA;IACE,yBAAA;EANF;AACF","sourcesContent":["@import '../../../styles/antd-variables.less';\n@import '../../../styles/common-variables.less';\n\n.successIcon {\n  color: @success-color;\n  font-size: 72px;\n  animation: zoomIn 0.7s cubic-bezier(0.68, -0.55, 0.27, 1.55), checkmark 1s 0.5s;\n  border-radius: 50%;\n  background-image: linear-gradient(53deg, transparent 0%, transparent 55%, @success-color 55%, @success-color 100%);\n  animation-fill-mode: forwards;\n  background-position: -21px;\n\n}\n\n@keyframes zoomIn {\n  0% {\n    transform: scale(0.2);\n\n  }\n  100% {\n    transform: scale(1);\n  }\n}\n\n@keyframes checkmark {\n  0% {\n    background-position: -21px;\n  }\n  100% {\n    background-position: 21px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"successIcon": "BfbWc4JRwCRIhQwweLeK",
	"zoomIn": "Ux7OOfAXSx1XcuXe5qBl",
	"checkmark": "AbWhhuCVs71FFPyrgiTg"
};
export default ___CSS_LOADER_EXPORT___;
