import { useLocation } from 'react-router';
import Store from '@copilot/common/store';
import BaseModal from '@copilot/common/components/modals/wrappers/base';
import { Button, Typography } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import {
	fontSizeMD,
	fontWeightNormal,
	spacingLG,
	spacingMD,
} from '@copilot/common/constant/commonStyles';
import { useEffect } from 'react';
import styles from './linkedInAuthResults.module.less';
import NotificationManager from '@copilot/common/utils/notificationManager';

type HostedAuthModalProps = {
	/**
	 * If the user is trying to login for another account
	 */
	isOtherAccount?: boolean;
};

/**
 * Modal to show Hosted Auth Success or Failure Notification
 */
export function HostedAuthCompletionModal({ isOtherAccount = false }: HostedAuthModalProps) {
	const location = useLocation();

	function onCancel() {
		Store.History.replace({ ...location, hash: '' });
	}

	useEffect(() => {
		if (location.hash === '#loginFailed') {
			NotificationManager.showErrorNotification({
				type: 'error',
				message: `Something went wrong while we were trying to sync ${
					isOtherAccount ? 'this' : 'your'
				} LinkedIn account. Please try
				again in a moment, or contact support if this problem persists.`,
				duration: null,
			});
			onCancel();
		}
	}, [location.hash]);

	return (
		<BaseModal
			width={520}
			open={location.hash === '#loginSuccess'}
			centered
			onOk={onCancel}
			onCancel={onCancel}
			footer={[
				<Button key="submit" type="primary" onClick={onCancel}>
					Keep Exploring
				</Button>,
			]}
		>
			<div style={{ textAlign: 'center' }}>
				<CheckCircleFilled className={styles.successIcon} />

				<Typography.Title level={4} style={{ marginBottom: spacingMD }}>
					{isOtherAccount ? 'This' : 'Your'} account is now syncing
				</Typography.Title>

				<Typography.Text
					style={{
						fontWeight: fontWeightNormal,
						fontSize: fontSizeMD,
						marginBottom: spacingLG,
					}}
				>
					LinkedIn login was successful! We're now syncing{' '}
					{isOtherAccount ? 'this' : 'your'} account to our platform. This might take a
					few moments.
					{isOtherAccount ? '' : " You'll be notified once everything is ready."}
				</Typography.Text>
			</div>
		</BaseModal>
	);
}
